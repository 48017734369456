export const seekMiddleware = (store) => (next) => (action) => {
  const { player, seek, timeLeft } = store.getState();
  const result = next(action);
  const { seek: newSeek, timeLeft: newTimeLeft } = store.getState();
  const hasNewChapter = newSeek !== seek;
  const hasNewTimeLeft =
    timeLeft !== Infinity &&
    newTimeLeft !== Infinity &&
    timeLeft - newTimeLeft > 1.5; // TODO: allow skipping backwards, not only forwards
  if (hasNewChapter || hasNewTimeLeft) {
    player.currentTime = hasNewChapter
      ? newSeek
      : player.duration - newTimeLeft;
    player.play();
  }
  return result;
};
