export default function createStore(rootReducer, middlewares) {
  if (middlewares !== undefined) {
    return middlewares(createStore)(rootReducer);
  }
  let state = rootReducer();
  let subscriptions = [];

  function subscribe(fn) {
    subscriptions = [...subscriptions, fn];
    return function unsubscribe() {
      subscriptions = subscriptions.filter(
        (subscription) => subscription !== fn
      );
    };
  }

  function getState() {
    return state;
  }

  function dispatch(action) {
    const prevState = state;
    state = rootReducer(state, action);
    subscriptions.forEach((subscription) => subscription(prevState, state));
    return state;
  }

  return { subscribe, getState, dispatch };
}
