import createLogger from './logger.js';

const log = createLogger();

const PREBID_TIMEOUT = 2000;

export default function prebidInstreamHandler() {
  log.info(
    `prebid instream: (Brick) Waiting max ${PREBID_TIMEOUT}ms for prebid instream done`
  );
  if (!document.querySelector('bazaar-config')) {
    return Promise.reject(new Error('Bazaar not defined'));
  }
  return new Promise((resolve, reject) => {
    let settled = false;

    const instreamDonePromise = new Promise((_resolve) => {
      const instreamDone = (event) => {
        window.removeEventListener('prebid:instream_done', instreamDone);
        if (!settled) {
          log.info(
            'prebid instream: (Brick) Received prebid:instream_done',
            event
          );
          _resolve({ adServerTargeting: event.data.adServerTargeting });
        }
        settled = true;
      };
      window.addEventListener('prebid:instream_done', instreamDone);
    });

    const instreamTimeoutPromise = new Promise((_, _reject) => {
      setTimeout(() => {
        if (!settled) {
          log.info(
            `prebid instream: (Brick) Prebid instream timed out (${PREBID_TIMEOUT}ms), rendring flowplayer`
          );
          _reject(
            new Error(
              `Timed out (${PREBID_TIMEOUT}ms) while waiting for prebid instream`
            )
          );
        }
        settled = true;
      }, PREBID_TIMEOUT);
    });

    Promise.race([instreamDonePromise, instreamTimeoutPromise])
      .then(({ adServerTargeting }) => {
        log.info(
          'prebid instream: (Brick) Adding flowplayer prebid targeting',
          adServerTargeting
        );

        // TODO: move parameters to flowplayer instantiation instead
        // to support multiple players / avoid leaking global state
        // HOW-TO: https://docs.flowplayer.com/plugins/ads#custom-macros
        // https://github.com/amedia/brick/issues/3204
        window.__flowplayerAdParameters = adServerTargeting;
        resolve({ adServerTargeting });
      })
      .catch((e) => {
        log.error(e);
        reject(e);
      });
  });
}
