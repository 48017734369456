import compose from './compose.js';

// basically a rip-off from https://github.com/reduxjs/redux/blob/master/src/applyMiddleware.ts
export default (...middlewares) =>
  (createStore) =>
  (...storeArgs) => {
    let dispatch;
    const store = createStore(...storeArgs);
    const chain = middlewares.map((middleware) =>
      middleware({
        getState: store.getState,
        dispatch: (...dispatchArgs) => dispatch(...dispatchArgs),
      })
    );
    dispatch = compose(...chain)(store.dispatch);
    return { ...store, dispatch };
  };
