// check if value is primitive
function isPrimitive(obj) {
  return obj !== Object(obj);
}
function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    // it's just the same object. No need to compare.
    return true;
  }

  if (isPrimitive(obj1) && isPrimitive(obj2)) {
    // compare primitives
    return obj1 === obj2;
  }
  if (
    (obj1 === null && obj2 !== null) ||
    (obj1 !== null && obj2 === null) ||
    (obj1 === undefined && obj2 !== undefined) ||
    (obj1 !== undefined && obj2 === undefined)
  ) {
    return false;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  const obj1Keys = Object.keys(obj1);

  for (let i = 0; i < obj1Keys.length; i++) {
    const key = obj1Keys[i];
    if (!obj2.hasOwnProperty(key)) {
      return false; // other object doesn't have this prop
    }
    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export default deepEqual;
