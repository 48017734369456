import bootstrapFlowplayer from '../../assets/flowplayer.js';
import prebidInstreamHandler from '../../utils/prebidInstreamHandler.js';

import { selectPlayerConfig } from '../selectors.js';
import {
  LOAD_PLAYER,
  PLAYER_LOADED,
  ADPLOGGER_SDK_LOADED,
} from '../actions.js';

export const loadPlayerMiddleware = (store) => (next) => (action) => {
  if (action.type !== LOAD_PLAYER) {
    return next(action);
  }
  const state = store.getState();
  const element = action.payload;
  const { version, isAudio, isAdsDisabled, isDebugLogging } = state;
  Promise.all([
    bootstrapFlowplayer({
      element,
      version,
      isAudio,
      isAdsDisabled,
      isDebugLogging,
    }),
    import('./adpLogger.js').catch(console.warn),
    isAdsDisabled || isAudio
      ? Promise.resolve()
      : prebidInstreamHandler().catch(console.warn),
  ]).then(([flowplayer, adpLogger]) => {
    const player = flowplayer(
      element.querySelector('.flowplayer'),
      selectPlayerConfig(state)
    );
    store.dispatch({
      type: PLAYER_LOADED,
      payload: { player },
    });
    if (!isAudio && adpLogger) {
      store.dispatch({ type: ADPLOGGER_SDK_LOADED, payload: { adpLogger } });
    }
  });
  return next(action);
};
