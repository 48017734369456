import { requestIdleCallback } from '../../utils/ric.js';
import { autoplayOptions } from '../types.js';
import { selectPlayerConfig } from '../selectors.js';

export const mediaIdMiddleware = (store) => (next) => (action) => {
  const { mediaId, player } = store.getState();
  const result = next(action);
  const nextState = store.getState();
  const { mediaId: newMediaId } = nextState;
  if (mediaId && mediaId !== newMediaId) {
    // TODO: this is a hack to get around a potential race condition
    // with the IMA-plugin requiring the url to be changed
    requestIdleCallback(() => {
      // ensure picking up latest changes to the state
      const playerConfig = selectPlayerConfig(store.getState());
      player.setOpts({ ...playerConfig, autoplay: autoplayOptions.true });
      player.setSrc(playerConfig.src);
    });
  }
  return result;
};
