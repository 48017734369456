import deepEqual from '../utils/deepEqual.js';

import { actions } from './actions.js';

export default function createReactiveProperties(element, properties, store) {
  const propEntries = Object.entries(properties);

  propEntries.forEach(([key, { type, attribute, defaultValue }]) => {
    const parsedValue = type.fromString(
      element.hasAttribute(attribute)
        ? element.getAttribute(attribute)
        : defaultValue,
      properties
    );
    store.dispatch(actions.propertyUpdated(key, parsedValue));
    Object.defineProperty(element, key, {
      get() {
        return store.getState()[key];
      },
      set(newValue) {
        if (!type.validate(newValue, properties)) {
          throw new TypeError(`Invalid value for ${key}: ${newValue}`);
        }
        store.dispatch(actions.propertyUpdated(key, newValue));
        element.setAttribute(attribute, type.toString(newValue));
      },
      configurable: true,
      enumerable: true,
    });
  });

  function attributeChangedCallback(attribute, _, newValue) {
    propEntries.forEach(([key, value]) => {
      if (value.attribute === attribute) {
        const parsedValue = value.type.fromString(newValue, properties);
        if (deepEqual(parsedValue, store.getState()[key])) {
          return;
        }
        if (!value.type.validate(parsedValue, properties)) {
          throw new TypeError(`Invalid value for ${key}: ${newValue}`);
        }
        store.dispatch(actions.propertyUpdated(key, parsedValue));
      }
    });
  }

  function detachStore() {
    propEntries.forEach(([key]) => {
      delete element[key];
    });
  }

  return { attributeChangedCallback, detachStore };
}
