/* global globalThis */
/* eslint-disable @typescript-eslint/no-empty-function */
const noop = () => {};

// TODO: support log thresholds instead of just disabling
export default function createLogger(
  logEnabled = globalThis.location?.search.includes('debug=true'),
  console = globalThis.console
) {
  return {
    ...console,
    info: logEnabled ? console.info.bind(console) : noop,
    warn: logEnabled ? console.warn.bind(console) : noop,
    error: logEnabled ? console.error.bind(console) : noop,
  };
}
