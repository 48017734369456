const token =
  'eyJraWQiOiJTbHhaN2hxeThlRzciLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJTbHhaN2hxeThlRzdcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.lkb7nSTggG6g73U7RbYn_db6odxvy2R8clke7929NYCTVBX97MPv9Tsyk1xJOapVX5QsQ2C40RqPDS7MyF8JYA';

function createAudioConfig({
  isAutoPlay,
  isScrollEnabled,
  mediaId,
  playOutsideViewport,
}) {
  return {
    token,
    src: `https://lw-amedia-cf.lwcdn.com/v-${mediaId}.mp4`,
    type: 'audio/mp4',
    float_on_scroll: isScrollEnabled,
    autopause: isScrollEnabled ? false : !playOutsideViewport,
    ui: 'USE_THIN_CONTROLBAR',
    poster: null,
    autoplay: isAutoPlay,
  };
}

function createVideoConfig({
  isAutoPlay,
  isScrollEnabled,
  isMuted,
  playOutsideViewport,
  uiOverrides,
  startQuality,
  videoTitle,
  playerId,
  startTime,
  mediaId,
  logo,
  loop,
}) {
  return {
    token,
    autopause: isScrollEnabled ? false : !playOutsideViewport,
    preload: 'auto',
    float_on_scroll: isScrollEnabled,
    muted: isMuted,
    skip_controls: true, // for playlist plugin
    src: `${playerId}/${mediaId}`,
    lang: 'nb',
    share: { link: true, facebook: true, twitter: true, iframe: false },
    ...(loop !== undefined && { loop }),
    ...(startTime !== undefined && { start_time: startTime }),
    ...(uiOverrides && { ui: uiOverrides }),
    ...(videoTitle && { title: videoTitle }),
    ...(isAutoPlay !== undefined && { autoplay: isAutoPlay }),
    ...(startQuality && { start_quality: startQuality }),
    ...(![undefined, null].includes(logo) && {
      logo: logo.toString(),
      logo_alt_text: '',
    }),
  };
}

export function getLogConfig(state) {
  const { player, mediaId, playerId } = state;
  return {
    autoplay: player.opts.autoplay > 0,
    title: player.opts.title,
    src: Array.isArray(player.opts.src) ? player.opts.src[0]?.src ?? '' : '',
    contentId: `flowplayer:${mediaId}`,
    playerId,
    autopause: player.opts?.autopause,
    live: player.playerState['is-live'] ?? false,
    length: (player.opts?.duration ?? 0) * 1000,
    startTime: player.opts?.start_time ?? 0,
    productFeature: 'adplogger_sdk',
  };
}

export function selectPlayerConfig(state) {
  if (state.isAudio) {
    return createAudioConfig(state);
  }

  return createVideoConfig(state);
}

/**
 * @typedef {import('@amedia/adplogger-sdk').AdpEvent.PlaybackEvent.payload} Payload
 * @param {{adpType: "playing" | "timeupdate" | "pause" | "ended", duration: number }}
 * @returns {Payload}
 */
export function adpLoggerEventDto({ adpType, duration }) {
  if (
    !['playing', 'timeupdate', 'pause', 'ended'].includes(adpType) ||
    Number.isNaN(duration) ||
    typeof duration !== 'number'
  ) {
    throw new Error(
      `Could not create payload duration "${duration}" for the event "${adpType}". Check the adpType and duration values`
    );
  }

  return {
    type: 'video',
    adpType,
    duration,
  };
}

/**
 * @typedef {import('@amedia/adplogger-sdk').AdpEvent.PlaybackAdEvent.payload} Payload
 * @param {{type: "ad-started" | "ad-paused" | "ad-resumed" | "ad-completed" | "ad-skipped", detail: {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number} | {vast_ad_id: string, ad_type: string} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number, adProgress: number, adDuration: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number, adProgress: number, adDuration: number, segmentDuration: number, adDuration: number, currentTime: number, adDuration: number, segmentDuration: number, adDuration: number}}
 * @returns {Payload}
 */
export function adpLoggerAdEventDto({
  adpType,
  adType,
  vastAdId,
  duration,
  adDuration,
}) {
  if (
    ![
      'ad-started',
      'ad-paused',
      'ad-resumed',
      'ad-completed',
      'ad-skipped',
    ].includes(adpType) ||
    Number.isNaN(duration) ||
    typeof duration !== 'number'
  ) {
    throw new Error(
      `Could not create payload duration "${duration}" for the event "${adpType}". Check the adpType and detail values!`
    );
  }

  return {
    type: 'video-ad',
    adType,
    adId: vastAdId,
    adpType,
    duration,
    length: adDuration,
  };
}

const SECONDS_PER_SECOND = 1;
const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

const designations = [
  ['D', SECONDS_PER_DAY],
  ['H', SECONDS_PER_HOUR],
  ['M', SECONDS_PER_MINUTE],
  ['S', SECONDS_PER_SECOND],
];

/**
 * Convert a number of seconds to an ISO 8601 duration string denominated
 * maximally in days.
 * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
 *
 * @param  {Number} seconds A number of seconds
 * @return {String}         An ISO 8601 duration string
 */
function secondsToDuration(seconds) {
  let duration = 'PT';
  let remainder = seconds;

  designations.forEach(([sign, seconds]) => {
    const value = Math.floor(remainder / seconds);

    remainder = remainder % seconds;

    if (value) {
      duration += `${value}${sign}`;
    }
  });

  if (duration === 'PT') {
    duration = 'PT0S';
  }

  return duration;
}

const createdAtRegExp = /.*?_(\d+)\.m3u8/;

export function getVideoMetadata(player) {
  if (!player?.opts) {
    return {};
  }
  const thumbnailUrl = player.opts?.poster ?? '';
  const title = player.opts.metadata?.title ?? 'No title';
  const description = player.opts.metadata?.description;
  const duration = player.opts.metadata?.duration;

  const srcArray = Array.isArray(player.opts.src) ? player.opts.src : [];

  const hlsSrc = srcArray.find(
    ({ type }) => type === 'application/x-mpegurl'
  )?.src;
  const contentUrl = srcArray.find(({ type }) => type === 'video/mp4')?.src;

  const base = {
    thumbnailUrl,
    contentUrl,
    title,
    description,
    duration: !!duration ? secondsToDuration(duration) : undefined,
    createdAt: undefined,
  };

  if (!hlsSrc || !createdAtRegExp.test(hlsSrc)) {
    return base;
  }

  const time = hlsSrc.replace(createdAtRegExp, '$1');
  const createdAt = time && new Date(parseInt(time)).toISOString();

  return {
    ...base,
    createdAt,
  };
}

/**
 * Generates the inline styles for a video player based on the provided aspect ratio, video title, and teaser title flag.
 *
 * @param {Object} options - The options object.
 * @param {string} options.aspectRatio - The aspect ratio of the video player, type AspectRatio.
 * @param {string} options.videoTitle - The title of the video.
 * @param {boolean} options.hasTeaserTitle - Flag indicating if the video has a teaser title.
 * @return {string} The generated inline styles for the video player.
 */

export const getCustomCSSProperties = ({
  aspectRatio,
  videoTitle,
  hasTeaserTitle,
}) => {
  const titleLength = hasTeaserTitle && videoTitle ? videoTitle.length : 0;
  return `${aspectRatio?.toStyle() || ''} ${
    hasTeaserTitle ? `--brick-player-title-length: ${titleLength}` : ''
  }`.trim();
};
